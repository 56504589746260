<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card
          color="success"
          icon="mdi-chat-outline"
          inline
          :title="$t('salon.les_salon')"
          class="px-5 py-3 mb-5"
        >
          <v-card
            v-for="salon in salons"
            :key="salon.identifiant"
          >
            <v-card-title>
              {{ salon.nom }}
              <v-card-subtitle>{{ salon.identifiant }}</v-card-subtitle>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :items="salon.listePrésences"
                :headers="headers"
                :no-data-text="$t('general.aucune_donnée_disponible')"
                :footer-props="{
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                  pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
              />
            </v-card-text>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

  import Salon from '@/services/salon.js'
  import i18n from '@/i18n.js'

  export default {
    data: () => ({
      salons: {},
      headers: [
        { text: i18n.t('salon.id'), value: 'usager.id' },
        { text: i18n.t('general.prenom'), value: 'usager.prenom' },
        { text: i18n.t('general.nom_de_famille'), value: 'usager.nom' },
        { text: i18n.t('salon.entree'), value: 'dateCréation' },
        { text: i18n.t('salon.ping'), value: 'datePing' },
      ],
    }),

    mounted () {
      Salon.listeSalons().then((r) => { this.salons = r })
    },
    created () {

    },
    methods: {
    },
  }
</script>
